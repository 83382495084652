import { useState } from 'react'
import { useGesture } from '@use-gesture/react'

import { getDomain } from '../../../helpers/layoutHelper'
import { openEmail } from '../../../helpers/interactionHelper'
import { Card, Group, Col, Row } from '../../../components/Page'
import Layout from '../../../components/Layout'
import Zzz from './FolioZzz'
import Button from '../../../components/Button'
import globals from '../../../globals/globals'
import './folio-sleeping.scss'

const colors = [
  globals.colors.primary,
  globals.colors.secondary,
  globals.colors.tertiary
]

const FolioSleeping = () => {
  const [mouse, setMouse] = useState([0, 0])
  const domain = getDomain()

  const bind = useGesture({
    onDrag: ({ xy }) => {
      setMouse([
        (xy[0] / window.innerWidth - 0.5) * 2,
        (xy[1] / window.innerHeight - 0.5) * 2
      ])
    },
    onMove: ({ xy }) => {
      setMouse([
        (xy[0] / window.innerWidth - 0.5) * 2,
        (xy[1] / window.innerHeight - 0.5) * 2
      ])
    }
  })

  return (
    <Layout className='sleeping'>
      <Card>
        <div className='zzz-events' {...bind()}>
          <Group>
            <Row className='center'>
              <Zzz mouse={mouse} colors={colors} />
            </Row>
            <Row className='center'>
              <h2>Sleeping</h2>
            </Row>
            <Row className='center'>
              <Col>
                <h4>
                  This folio is sleeping to save energy.
                  <br />
                  To wake it up, please reach out.
                </h4>
                <div className={'btn-wrapper active'}>
                  <div className='outline' />
                  <Button
                    title={`matt@volt.${domain}`}
                    onClick={() => openEmail('matt-sleeping')}
                  />
                </div>
              </Col>
            </Row>
          </Group>
        </div>
      </Card>
    </Layout>
  )
}

export default FolioSleeping
