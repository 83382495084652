import axios from 'axios'

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
const directusUrl: any = process.env.REACT_APP_DIRECTUS_URL
const timeout = 5000
const axiosApi = axios.create({ baseURL: directusUrl, timeout })

const assetsUrl = `${process.env.REACT_APP_DIRECTUS_URL}/assets`
let assets: any = null

const _getAssets = async () => {
  try {
    const req: any = await axiosApi.post<any>('/graphql/system', {
      query: `
        query Assets {
          files {
            filename_disk
            filename_download
          }
        }
      `
    })
    const newAssets: any = req?.data?.data?.files
    if (!newAssets) return false
    assets = newAssets.map((a: any) => ({
      id: a.filename_download,
      uri: a.filename_disk
    }))
    return true
  } catch (err) {
    isDev && console.log(err)
    return false
  }
}

const getAssetUrl = (id: string, params: any) => {
  if (!assets) return null
  const uri = assets.find((a: any) => a.id === id)?.uri
  if (!uri) return null
  const paramsString = params
    ? `?${Object.entries(params)
        .map(([key, value]: any) => {
          return `${key}=${value}`
        })
        .join('&')}`
    : ''
  return `${assetsUrl}/${uri}${paramsString}`
}

const getFolio = async (id: string) => {
  try {
    // Get image and video Ids first
    const assetsReq = await _getAssets()
    if (!assetsReq) return null
    // Get and return folio data
    const req: any = await axiosApi.post<any>('/graphql', {
      query: `
        query FolioById($id: String = "${id}") {
          folio(filter: { id: { _eq: $id } }) {
            client {
              title
              recipient
            }
            title
            message
            active
            projects {
              project_id {
                id
              }
            }
          }
        }
      `
    })
    const folio: any = req?.data?.data?.folio?.[0]
    if (!folio) return null
    const { active, client: folioClient, message, projects, title } = folio
    let res = {
      active,
      client: folioClient?.title,
      recipient: folioClient?.recipient,
      message,
      projects: projects?.map((p: any) => p.project_id?.id),
      title
    }
    return res
  } catch (err) {
    isDev && console.log(err)
    return null
  }
}

export { getFolio, getAssetUrl }
