import { useRef, useState, useMemo } from 'react'
import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { Canvas, useFrame } from '@react-three/fiber'
import HandShader from '../../../shaders/HandShader'

const rangeY = [-0.5, 0.5]
const rangeFadeOut = 0.6
const fadeRate = 0.02

const ZzzNode = ({ colors, position, delay = 0 }) => {
  const meshRef = useRef(null)
  const loading = useRef(true)
  const delaySecs = delay / 1000

  // Load models
  const [obj, setObj] = useState(null)
  useMemo(() => {
    if (!loading.current) return
    new OBJLoader().load(`${process.env.PUBLIC_URL}/obj/zzz.obj`, model => {
      // Update base material of each mesh
      const mat = new THREE.ShaderMaterial(
        HandShader({
          colorA: new THREE.Color(colors[0]),
          colorB: new THREE.Color(colors[2]),
          opacity: 0
        })
      )
      // const mat = new THREE.MeshLambertMaterial({
      //   color: 'white',
      // })
      model.traverse(m => {
        if (!m.material) return
        m.material = mat
      })
      loading.current = false
      // Set model in state
      setObj(model)
    })
  }, [colors])

  useFrame(({ clock }) => {
    if (!meshRef.current) return
    const mesh = meshRef.current
    // Delay
    const t = clock.getElapsedTime()
    if (delaySecs > t) return
    // Reset
    if (mesh.position.y > rangeY[1]) {
      mesh.position.y = rangeY[0]
      mesh?.traverse(m => {
        const val = m?.material?.uniforms?.opacity?.value
        if (val !== undefined) {
          m.material.uniforms.opacity.value = 0
        }
      })
      // Animate
    } else {
      // Move
      mesh.position.y += 0.004
      // Rotate
      mesh.rotation.y = THREE.MathUtils.degToRad(
        Math.sin((t + delaySecs) * 2) * 45
      )
      // Fade in / out
      const isFadeOut = mesh.position.y > rangeY[1] * rangeFadeOut
      mesh?.traverse(m => {
        const val = m?.material?.uniforms?.opacity?.value
        if (val !== undefined) {
          if (isFadeOut) {
            if (val > 0) {
              m.material.uniforms.opacity.value -= fadeRate
            }
          } else {
            if (val < 1) {
              m.material.uniforms.opacity.value += fadeRate
            }
          }
        }
      })
    }
  })
  if (!obj) return null
  return (
    <mesh ref={meshRef} position={position}>
      <primitive object={obj} />
    </mesh>
  )
}

const ZzzScene = ({ colors }) => {
  return (
    <>
      {/* Lights */}
      <pointLight position={[0, 2, 2]} color='white' />

      {/* Action */}
      <group>
        <ZzzNode colors={colors} position={[-1, rangeY[0], 0]} delay={1200} />
        <ZzzNode colors={colors} position={[0, rangeY[0], 0]} delay={600} />
        <ZzzNode colors={colors} position={[1, rangeY[0], 0]} />
      </group>
    </>
  )
}

const Zzz = ({ colors, mouse }) => {
  return (
    <div className='zzz'>
      <Canvas
        camera={{
          fov: 30,
          position: [0, 0, 6],
          near: 0.1,
          far: 20000
        }}
      >
        <ZzzScene colors={colors} mouse={mouse} />
      </Canvas>
    </div>
  )
}

export default Zzz
