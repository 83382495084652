import { useEffect, useState } from 'react'
import { logEvent } from './analyticsHelper'

const useScrolled = (ref: any, threshold: number = 0.5) => {
  const [scrolled, setScrolled] = useState(false)
  useEffect(() => {
    const onScroll = () => {
      if (!ref.current) return
      const { top } = ref.current.getBoundingClientRect()
      top < window.innerHeight * threshold && setScrolled(true)
    }
    onScroll()
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [ref, threshold, scrolled])
  return scrolled
}

const openEmail = (type: string) => {
  const isNz = window.location.href.includes('.nz')
  const country = isNz ? 'nz' : 'co.uk'
  const emails: any = {
    volt: {
      email: `hello@volt.${country}`,
      subject: `👋 Hello`,
      body: `I'm%20looking%20for...`
    },
    matt: {
      email: `matt@volt.${country}`,
      subject: `👋 Hello`,
      body: ``
    },
    'matt-sleeping': {
      email: `matt@volt.${country}`,
      subject: `💤 Wake up`,
      body: `I'd like to view your folio at: ${window.location.href}.`
    }
  }
  if (!emails[type]) return
  const { email, subject, body } = emails[type]
  // @ts-ignore
  window.location = `mailto:${email}?subject=${subject}&body=${body}`
  logEvent('contact_pressed', { email })
}

export { useScrolled, openEmail }
